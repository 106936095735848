import {
  type ConsentOptions,
  DataProtectionContext
} from '../../contexts/DataProtectionProvider'
import { LabelledCheckbox } from '../Checkbox'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { Link } from 'gatsby'
import React, { useCallback, useContext, useState } from 'react'

const ConsentDialog: React.FC = () => {
  const {
    consent,
    setConsent,
    setShowConsentBanner,
    setShowConsentDialog,
    showConsentDialog
  } = useContext(DataProtectionContext)

  const [selectedConsent, setSelectedConsent] = useState<ConsentOptions>({
    analytics: consent.analytics,
    functional: consent.functional,
    required: true
  })

  const handleClose = useCallback(
    (showBannerAgain: boolean = false) => {
      setShowConsentDialog(false)
      if (showBannerAgain && !consent.required) {
        setShowConsentBanner(true)
      } else {
        setShowConsentBanner(false)
      }
    },
    [consent, setShowConsentBanner, setShowConsentDialog]
  )

  const handleAcceptRequiredOnly = useCallback(() => {
    setConsent({
      analytics: false,
      functional: false,
      required: true
    })
    handleClose()
  }, [handleClose, setConsent])

  const handleAcceptCustomOptions = useCallback(() => {
    setConsent(selectedConsent)
    handleClose()
  }, [handleClose, selectedConsent, setConsent])

  const handleLinkClick = useCallback(() => {
    handleClose(true)
  }, [handleClose])

  return (
    <Dialog
      onClose={() => {
        return handleClose(true)
      }}
      open={showConsentDialog}>
      <DialogTitle>
        <h3 className="my-2">Your choice regarding cookies on this site 🍪</h3>
      </DialogTitle>
      <DialogContent id="cookie-dialog">
        <p>
          Please choose whether this site may use Functional and/or
          Personalization cookies, as described below. By selecting{' '}
          <strong>Required cookies only</strong> of by closing this message, you
          are requesting ICOSE explicitly not to sell or share your personal
          information. Refer to our{' '}
          <Link onClick={handleLinkClick} to="/cookie-policy">
            Cookie Policy
          </Link>
          &nbsp;for more details on these options.
        </p>
        <div className="my-4">
          <LabelledCheckbox
            checked={selectedConsent.required}
            disabled
            label={
              <>
                <div className="font-bold">Required cookies</div>
                <p>
                  Required cookies are used for basic website functionality.
                  Some of the cookies we use are essential for parts of the
                  website to operate.
                </p>
              </>
            }
            name="required"
          />
          <LabelledCheckbox
            checked={selectedConsent.functional}
            label={
              <>
                <div className="font-bold">Functional cookies</div>
                <p>
                  Functional cookies are used to remember your preferences on
                  our websites. Functional cookies are cookies that are
                  essential for the website to function properly.
                </p>
              </>
            }
            name="functional"
            onChange={(event) => {
              setSelectedConsent({
                ...selectedConsent,
                functional: event.target.checked
              })
            }}
          />
          <LabelledCheckbox
            checked={selectedConsent.analytics}
            label={
              <>
                <div className="font-bold">Analytics cookies</div>
                <p>
                  Analytics cookies help us understand how our visitors interact
                  with the website. It helps us understand the number of
                  visitors, where the visitors are coming from, and the pages
                  they navigate. This information helps us improve our website
                  experience.
                </p>
              </>
            }
            name="analytics"
            onChange={(event) => {
              setSelectedConsent({
                ...selectedConsent,
                analytics: event.target.checked
              })
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="m-2 flex gap-2">
          <button
            className="button"
            onClick={handleAcceptRequiredOnly}
            type="button">
            Required Only
          </button>
          <button
            className="button highlighted"
            onClick={handleAcceptCustomOptions}
            type="button">
            Accept Selected
          </button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConsentDialog
